.progress-indicator {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 20px 0;
}

.step-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  position: relative;
}

.step-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 70px; /* Adjust this value as needed */
  justify-content: flex-start;
}

.step-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  z-index: 2;
}

.step-circle.active {
  background-color: #007bff;
  color: white;
}

.step-circle.completed {
  background-color: #28a745;
  color: white;
}

.step-title {
  font-size: 12px;
  color: #6c757d;
  text-align: center;
  max-width: 100px;
  word-wrap: break-word;
}

.step-connector {
  position: absolute;
  top: 15px;
  left: calc(50% + 15px);
  right: calc(-50% + 15px);
  height: 2px;
  background-color: #e0e0e0;
}

.step-connector.completed {
  background-color: #28a745;
}

@media (max-width: 768px) {
  .progress-indicator {
    flex-direction: column;
    align-items: flex-start;
  }

  .step-container {
    flex-direction: row;
    width: 100%;
    margin-bottom: 0.5rem;
  }

  .step-content {
    flex-direction: row;
    height: auto;
    align-items: center;
  }

  .step-circle {
    margin-right: 1rem;
    margin-bottom: 0;
  }

  .step-title {
    text-align: left;
    max-width: none;
  }

  .step-connector {
    display: none;
  }
}
